<script setup lang="ts">
const { t } = useI18n();
const { pushError } = useNotifications();

const emit = defineEmits(["update:base64-data"]);
const selectedImages = ref<string[]>([]);
const isDragging = ref<boolean>(false);
const fileInput = ref<HTMLInputElement | null>(null);
const hoveredIndex = ref<number | null>(null);
const showErrorAnimation = ref<boolean>(false);

const handleFileChange = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const validFiles = await filterValidFiles(Array.from(target.files));
    selectedImages.value = [...selectedImages.value, ...validFiles];
    emit("update:base64-data", selectedImages.value);
  }
};

const handleDragOver = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = true;
};

const handleDragLeave = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
};

const handleDrop = async (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
  const files = event.dataTransfer?.files;
  if (files && files.length > 0) {
    const validFiles = await filterValidFiles(Array.from(files));
    selectedImages.value = [...selectedImages.value, ...validFiles];
    emit("update:base64-data", selectedImages.value);
  }
};

const maxFileSizeMB = 5; // Max Mb
const filterValidFiles = (files: File[]): Promise<string[]> => {
  const allowedExtensions = ["jpg", "jpeg", "heic", "mp4", "pdf", "png"];

  return new Promise((resolve, reject) => {
    const promises = files.map((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase() ?? "";
      const isValidExtension = allowedExtensions.includes(fileExtension);
      const isWithinSizeLimit = file.size <= maxFileSizeMB * 1024 * 1024;

      if (!isValidExtension) {
        pushError(t("form.contactForm.uploadFormatError"));
        showErrorAnimation.value = true;
        setTimeout(() => {
          showErrorAnimation.value = false;
        }, 1000);
        return Promise.reject(t("form.contactForm.uploadFormatError"));
      }
      if (!isWithinSizeLimit) {
        pushError(
          t("form.contactForm.uploadSizeError", { maxSize: maxFileSizeMB }),
        );
        showErrorAnimation.value = true;
        setTimeout(() => {
          showErrorAnimation.value = false;
        }, 1000);
        return Promise.reject(
          t("form.contactForm.uploadSizeError", { maxSize: maxFileSizeMB }),
        );
      }

      return fileToBase64(file);
    });

    Promise.all(promises)
      .then((base64Strings) => resolve(base64Strings))
      .catch((error) => reject(error));
  });
};

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const triggerFileInput = () => {
  fileInput.value.value = "";
  fileInput.value?.click();
};

const removeImage = (index: number) => {
  selectedImages.value.splice(index, 1);
  emit("update:base64-data", selectedImages.value);
};
</script>

<template>
  <div class="w-full">
    <div class="text-scheppach-primary-500">
      {{ t("form.contactForm.documentUpload") }}
    </div>
    <div
      class="min-h-200px bg-scheppach-neutral-50 p-3"
      :class="{ 'bg-scheppach-primary-50': isDragging }"
      @dragover.prevent="handleDragOver"
      @dragleave="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <div
        class="min-h-40 p-3 border-2 border-dashed border-scheppach-primary-100 w-full h-full flex justify-start items-center relative overflow-x-auto"
      >
        <input
          ref="fileInput"
          type="file"
          class="hidden"
          multiple
          @change="handleFileChange"
        />
        <div
          v-if="selectedImages.length === 0"
          class="text-6 text-scheppach-primary-300 mx-auto"
        >
          {{ t("form.contactForm.documentUploadPlaceholder") }}
          <p class="text-sm">
            {{
              t("form.contactForm.supportedFileFormats", {
                formats: "JPG, JPEG, PNG, PDF, HEIC, MP3, MP4",
                maxSize: maxFileSizeMB,
              })
            }}
          </p>
        </div>
        <div class="flex gap-2">
          <div
            v-for="(image, index) in selectedImages"
            :key="index"
            class="relative flex-shrink-0 w-32 h-32"
            @mouseover="hoveredIndex = index"
            @mouseleave="hoveredIndex = null"
          >
            <img
              :src="image"
              alt="Selected Image"
              class="h-32 w-32 object-cover transition duration-100 flex-shrink-0"
              :class="{
                'opacity-50 scale-110': hoveredIndex === index,
              }"
            />
            <span
              v-if="hoveredIndex === index"
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-1 text-scheppach-error-500 z-10 cursor-pointer"
              @click="removeImage(index)"
            >
              <i class="i-sl-bin-1 inline-block v-mid size-8"></i>
            </span>
          </div>
        </div>
      </div>
      <div
        class="mt-3 w-full sm:w-45 text-center cursor-pointer rounded px-3 py-2 bg-scheppach-primary-500 hover:bg-scheppach-primary-700 text-white select-none"
        :class="{ 'wiggle-animation': showErrorAnimation }"
        @click="triggerFileInput"
      >
        {{ t("form.contactForm.selectFile") }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.wiggle-animation {
  animation: wiggle 0.2s 4 alternate;
}

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}
</style>
